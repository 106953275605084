.page-actualites,.node-type-vue{
  .view-actualit-s{
    .row + .row{
      margin-top: 20px;
    }
    .col{
      //padding-bottom: 60px;
      position: relative;
      @media (max-width: @screen-sm-max) {
        &+.col{
          margin-top: 20px;
        }
      }
      .actu-img{
        position: relative;
        display: inline-block;
		a{
			display: block;
			&::before{
				background: rgba(0,0,0,0.5);
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: background 0.35s;
				
			}
		}
        .actu-effect{
		  display: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
		  background: rgba(0,0,0,0.5);
		  transition: background 0.35s;
          /*&::before{
            position: absolute;
            content: '';
            top: 50px;
            right: 30px;
            bottom: 50px;
            left: 30px;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: scale(0,1);
            transform-origin: 0 0;
            opacity: 0;
            transition: opacity 0.35s, transform 0.35s;
          }

          &::after{
            position: absolute;
            content: '';
            top: 30px;
            right: 50px;
            bottom: 30px;
            left: 50px;
            border-right: 1px solid #fff;
            border-left: 1px solid #fff;
            transform: scale(1,0);
            transform-origin: 100% 0;
            opacity: 0;
            transition: opacity 0.35s, transform 0.35s;
          }*/
        }

      }
      &:hover{
        /*.actu-img .actu-effect::before,.actu-img .actu-effect::after{
          transform: scale(1);
          opacity: 1;
        }*/
		.actu-img a::before{
			background: rgba(0,0,0,0);
		}
      }
      .actu-title{
        color: @red;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        a:hover{
          color: @red;
        }
      }
      .actu-text{
        //height: 140px;
        color: @grey-darker;
		text-align: justify;
      }
      .actu-link{
        /*position: absolute;
        bottom: 0px;
        left: 0px;
        padding-left: @grid-gutter-width/2;
        padding-right: @grid-gutter-width/2;*/
        margin-top: 10px;
        margin-bottom: 10px;
        a{
          position: relative;
          font-weight: bold;
          //color: @white;
          //background: @grey-darker;
          //border: 2px solid @grey-darker;
          color: @grey-darker;
          display: inline-block;
          //padding: 5px 5px;
          .glyphicon-menu-right;
          text-decoration: none;
          &::before{
            .glyphicon;
            margin-right: 5px;
            font-size: 10px;
            //vertical-align: text-top;
          }
          &:hover{
            //color: @grey-darker;
            //background: @white;
            color: @red;
          }
        }
      }
    }
  }
}
