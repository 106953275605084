.front{
  #block-views-slider-block{
    margin-left: -15px;
    margin-right: -15px;
    .flexslider{
      margin-bottom: 0px;
      .views-field-body{
        position: static;
        background: @red;
        color: @white;
        z-index: 999;
        .field-content{
          padding: 1rem 2.5rem;
          a{
            .btn();
            .btn-info();
          }
        }
      }
      .slides{
        overflow: hidden;
      }
      .flex-control-nav{
        bottom: 60px;//17px;
        z-index: 10;
        a{
          background: @white;
          box-shadow: none;

          &.flex-active{
            background: @red;
          }
        }
      }
      .flex-direction-nav{
        a{
          height: 50px;
          color: @white;
          &::before{
              color: @white;
          }
        }
      }
    }
  }
  .block-accroche-home{
    text-align: center;
    margin-top: @margin-top-home;
    font-size: 20px;
  }
  #block-views-t-moignages-block{
    margin-left: -15px;
    margin-right: -15px;
    margin-top: @margin-top-home;
    .block-title{
      text-align: center;
      color: @red;
      font-size: 23px;
      text-transform: uppercase;
      margin-bottom: @margin-top-home;
      margin-top: 0px;
      &::before{
        content: '';
        display: inline-block;
        height: 25px;
        width: 25px;
        background-image: url("../img/home/icon-confiance.svg");
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: bottom;
        margin-right: 10px;
      }
    }
    .view-content{
      //background: url("../img/home/temoignages-bg.jpg");
      //height: 525px;
      padding: 0px 50px;
      .col{
        text-align: center;
        color: @grey-darker;
        .field-content{
          padding-left: 20px;
          padding-right: 20px;
        }
        .views-field-body::before{
          font-family: FontAwesome;
          content: "\f10d";
          color: @red;
          font-size: 36px;
        }
        .views-field-title{
          margin-top: 20px;
          color: @red;
          font-weight: bold;
        }
      }
      /*.flexslider{
        background: rgba(255, 255, 255, 0.6);
        border: none;
        height: 100%;
        text-align: center;
        margin: 0px;
        .slides{
          @media (min-width: @screen-sm-min) {
            font-size: 20px;
          }
          display: flex;
          height: 100%;
          @media (min-width: @screen-sm-min) {
            padding: 0px 80px 50px 80px;
          }
          li{
            margin-top: auto;
            margin-bottom: auto;
            .views-field-title{
              margin-top: 30px;
              border-top: 2px solid @black;
              padding: 20px 30px;
              display: inline-block;

            }
          }
        }
        .flex-control-nav{
          bottom: -20px;
          z-index: 10;
          a{
            background: @grey;
            box-shadow: none;

            &.flex-active{
              background: @red;
            }
          }
        }
        .flex-direction-nav{
          a{
            height: 50px;
            color: @grey;
            &::before{
                color: @grey;
            }
          }
        }
      }*/
    }
  }
  .block-raccourci-home{
    margin-top: @margin-top-home;
    .col{
      a{
        text-decoration: none;
        text-align: center;
        display: block;
        padding: 10px;
        padding-bottom: 20px;
        &::before{
          box-sizing: inherit;
          content: '';
          position: absolute;
          border: 2px solid transparent;
          width: 0;
          height: 0;
          top: 0;
          right: 0;
        }
        &::after{
          box-sizing: inherit;
          content: '';
          position: absolute;
          border: 2px solid transparent;
          width: 0;
          height: 0;
          bottom: 0;
          left: 0;
        }

        &:hover{
          /*&::before{
            transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
            border-top-color: @red;
            border-right-color: @red;
            width: 100%;
            height: 100%;
          }
          &::after{
            transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
            border-bottom-color: @red;
            border-left-color: @red;
            width: 100%;
            height: 100%;
          }*/
          .titre::before{
            background-size: 150px 150px;
            transition: all 0.5s ease-out;
          }
        }
        .titre{
          color: @red;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 24px;
          height: 80px;
          margin-top: 170px;
          position: relative;
          &::before{
            content: '';
            display: block;
            height: 170px;
            background-image: url("../img/home/icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 120px 120px;
            margin-bottom: 10px;
            position: absolute;
            bottom: 100%;
            width: 100%;
            transition: all 0.5s ease-out;
          }
        }
        .text{
          color: @black;
          display: block;
        }

      }
      &:nth-child(2) a .titre::before{
        background-image: url("../img/home/icon2.svg");
      }
      &:nth-child(3) a .titre::before{
        background-image: url("../img/home/icon3.svg");
      }
      &:nth-child(4) a .titre::before{
        background-image: url("../img/home/icon4.svg");
      }
    }
  }
  .block-raccourci-red-home{
    margin-top: @margin-top-home;
    background: @red;
    padding-top: @margin-top-home;
    padding-bottom: @margin-top-home;
    margin-left: -15px;
    margin-right: -15px;
    .col{
      text-align: center;
      color: @white;

      .content{
        position: relative;
        padding-bottom: 20px;
        padding-top: 20px;


      }
      a{
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        &::before{
          box-sizing: inherit;
          content: '';
          position: absolute;
          border: 2px solid transparent;
          width: 0;
          height: 0;
          top: 0;
          right: 0;
        }
        &::after{
          box-sizing: inherit;
          content: '';
          position: absolute;
          border: 2px solid transparent;
          width: 0;
          height: 0;
          bottom: 0;
          left: 0;
        }
        &:hover {
          &::before{
            transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
            border-top-color: @white;
            border-right-color: @white;
            width: 100%;
            height: 100%;
          }
          &::after{
            transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
            border-bottom-color: @white;
            border-left-color: @white;
            width: 100%;
            height: 100%;
          }
        }
      }
      h3,.h3{
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 15px;
        color: @white;
        display: block;
        &::before{
          content: '';
          display: block;
          height: 170px;
          background-image: url("../img/home/icon6.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 60%;
        }
      }
      &:nth-child(2) h3::before,&:nth-child(2) .h3::before{
        background-image: url("../img/home/icon5.svg");
      }
      &:nth-child(3) h3::before,&:nth-child(3) .h3::before{
        background-image: url("../img/home/icon7.svg");
      }
      .btn-vite{
        .btn();
        .btn-info();
        z-index: 97;

      }
    }
  }
  #block-views-actualit-s-block{
    margin-left: -15px;
    margin-right: -15px;
    margin-top: @margin-top-home;

    .block-title{
      text-align: center;
      color: @red;
      font-size: 23px;
      text-transform: uppercase;
      margin-bottom: @margin-top-home;
      margin-top: 0px;
      &::before{
        content: '';
        display: inline-block;
        height: 25px;
        width: 25px;
        background-image: url("../img/home/icon-confiance.svg");
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: bottom;
        margin-right: 10px;
      }
    }
    .hm-actu{
      @media (min-width: @screen-sm-min) {
        height: 440px;
      }
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .content{
        @media (max-width: @screen-xs-max) {
          text-align: center;
        }
        @media (min-width: @screen-sm-min) {
            width: 50%;
        }

        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        padding: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .title{
            font-size: 22px;
            margin-bottom: 20px;
        }
        .text{

        }
        .link{
          margin-top: 20px;
          text-align: right;
          a{
            .btn();
            .btn-info();
            border: none;
          }
        }

      }
    }
  }
}
