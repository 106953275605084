body{
	font-size: 16px;
}
.main-container{
  margin-top: 141px;
    @media (max-width: @screen-xs-max) {
      margin-top: 70px;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      margin-top: 105px;
    }
  #page-header{
    .page-header{
      margin-top: 50px;
      color: @red;
      border: none;
      text-align: center;
      font-size: 45px;
      text-transform: uppercase;
    }
    .bg-hearder-page{
      width: 100%;
      display: block;
      /*height: 500px;*/
      background-size: cover;
      background-position: center;
	  img{
		width: 100%;
	  }
    }
  }
  .flexslider{
    border: none;
  }
}
a:link, a:visited, a:hover, a:active { outline:none }

.pager li > a{
  border-color: @red;
  &:hover{
    background: @red;
    color: @white;
  }
}

