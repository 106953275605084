.page-nos-restaurants,.node-type-vue.page-node-22{
  .page-header{
    display: none;
  }
  .flexslider{
    margin-bottom: 0px;
  }

  .view-nos-restaurants.view-display-id-page,
  .view-nos-restaurants.view-display-id-block_1{
    padding-top: 40px;
    padding-bottom: 40px;
    background: @red;
    margin-left: -@grid-gutter-width/2;
    margin-right: -@grid-gutter-width/2;

    padding-left: @grid-gutter-width/2;
    padding-right: @grid-gutter-width/2;
  }
  .view-display-id-attachment_1{
    .view-empty{
      .leaflet-marker-pane{
        display: none;
      }
    }
  }

  .block-text-page-restaurants{
    color: @white;
    padding-top: 0px;
    padding-bottom: 40px;
    text-align: center;
  }
  .view-left{
    .views-exposed-form{
      position: relative;
      .views-exposed-widget{
        float: none;
      }
      .views-widget-filter-field_geofield_distance{
        width: 100%;
        padding:0px 45px 0px 0px;
        label{
          display: none;
        }
        .form-item-field-geofield-distance-distance,
        .form-item-field-geofield-distance-unit,
        .geofield-proximity-origin-from{
          display: none;
        }
        .form-item{
          width: 100%;
          float: none;
          margin: 0px;
          padding: 0px;
          input{
            border-radius: 0px;
          }
        }
      }
      .views-widget-filter-field_type_de_restauration_tid{
        width: 100%;
        padding-right: 0px;
        & > label{
          color: @white;
          margin-right: 10px;
        }
        .views-widget{
          display: inline-block;
        }
        .bef-select-as-checkboxes{
          height: auto;
          background: transparent;
          border-radius: 0px;
          box-shadow: none;
          border: none;
          padding: 0px;
          .bef-checkboxes{
            .form-item{
              display: inline-block;
              label{
                margin-bottom: 0px;
                color: @white;
                text-transform: uppercase;
              }
              input{
                display: inline-block;
                width: auto;
                height: auto;
                vertical-align: middle;
                margin-top: 0px;
              }
              & + .form-item{
                margin-left: 15px;
              }
            }
          }
        }
      }
      .views-submit-button{
        position: absolute;
        right: 0px;
        top: 0px;
        padding:0px;
        .form-submit.btn-info{
          margin-top: 0px;
          font-size: 0px;
          padding: 4px 10px;
          &::before{
            font-size: 16px;
            content: "\e062";
            border:none;
            position: relative;
            margin: 0px;
            padding: 0px;
            top: 0px;
            left: 0px;
          }
          &:hover{
            border-color: @white;
          }
        }

      }
  }
  .view-empty{
    height: 400px;
    background: @white;
    padding: 20px;
    color: @red;
  }
  .view-content{
    height: 400px;
    background: @white;
    overflow-y: scroll;
    .views-row{
      display: flex;
      padding: 10px;
      & + .views-row{
        margin-top: 20px;

      }
      &>div{
        flex-grow: 1;
      }
      .infos{
        //flex-shrink: 0;
        flex-grow: 8;
      }
      .views-field-distance,
      .views-field-field-geofield-distance{
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        &::before{
          content: "";
          background-image: url("../img/mini-picto-map-francesca.png");
          width: 32px;
          height: 32px;
          display: block;
          margin-left: auto;
          margin-right: auto;

        }
		.field-content{
			display: none;
		}
      }
      .views-field-field-type-de-restauration{
        color: @red;
        text-transform: uppercase;
      }
      .views-field-view-node{
        text-align: center;
         align-self: center;
        a{
          .btn();
          .btn-plus();
        }
      }

    }
  }
}
}
