header.navbar.navbar-default {
  background: transparent;
  border: none;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: all ease-out 1s;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: @white;
  top: 0px;
  border-radius: 0px;
  @media (max-width: @screen-xs-max) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &.sticky {
  	box-shadow: 3px 1px 0.99px 0.01px rgba(0, 0, 0, 0.05);
    padding-top: 10px;
    transition: padding-top ease-out 1s;
    padding-bottom: 0px;
    .region-navigation{
      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        top: 30px;
        transition: all ease-out 1s;
      }

    }
  }
  .navbar-header{
    @media (max-width: @screen-sm-max) {
      float: none;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      text-align: center;
    }
    .logo{
      @media (max-width: @screen-xs-max) {
        img{
          margin-top: 5px;
          width: 150px;
        }
      }
      @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        img{
          width: 200px;
        }
      }
    }
  }
  #navbar-collapse{
    margin-top: 25px;
    text-align: center;
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      margin-top: 12px;
    }
  }

  .navbar-nav{
    @media (min-width: @screen-sm-min) {
      display: inline-block;;
      float: none;
    }
    li{
      &+li{
        @media (min-width: @screen-sm-min) {
          &:before{
            content:"|";
            display: inline-block;
            color: @red;
          }
        }
      }
      a{
        color: @red;
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        padding: 10px;
        font-size: 15px;
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
          //background: black;
          padding: 5px;
          font-size: 14px;
        }
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
          font-size: 15px;
          //background: blue;
        }
        &:after{
          display: block;
          content: "";
          height: 2px;
          bottom: 0px;
          left: 0px;
          width: 0px;
          background: @red;
          transition: width 1s ease;
          margin-left: auto;
          margin-right: auto;
          margin-top: 5px;
        }
        &:hover,&.active{
          background: transparent;
          color: @red;
          &:after{
            width: 100%;
          }
        }
      }
    }
  }

}
.region-navigation{
  @media (min-width: @screen-sm-min) {
    float: right;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    position: absolute;
    top: 50px;
    right: 30px;
    transition: all ease-out 1s;
  }

}

.language-switcher-locale-url{
      margin: 0px;
      padding: 0px;
      &::before{
        content: " ";
        display: table;
      }
      &::after{
        content: " ";
    display: table;
      }
    li{
      display: inline-block;
      a{
        text-transform: uppercase;
        color: @red;
        text-decoration: none;
        padding: 10px;
        border-radius: 50%;
        font-weight: bold;
        display: inline-block;
        transition: transform 1s;
        width: 40px;
        height: 40px;
        &.active,&:hover{
          background: @red;
          color: @white;
        }
        &:hover{
          transform: rotate(1turn);
          transition: transform 1s;
        }
      }
    }
}
