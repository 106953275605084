.node-type-page{
  .main-container{

    .region-content{
      .paragraphs-items > .field-type-paragraphs > .field-items{

        /*& >.field-item + .field-item{
          margin-top: 40px;
        }*/
        .field-type-text-long{
          ul{
            padding-left: 15px;
            li+li{
              margin-top: 10px;
            }
          }
          h3,h4{
			position: relative;
            .glyphicon-record;
            &::before{
              .glyphicon;
              vertical-align: middle;
              font-size: 12px;
              line-height: 1;
              margin-right: 5px;
              margin-top: -5px;
            }
			&.picto-contrat{
			padding-left: 50px;
			
				&::before{
					content:"";
					background: url(../img/picto_contrat.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
			&.picto-redevance{
			padding-left: 50px;
			
				&::before{
					content:"";
					background: url(../img/picto_redevance.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
			&.picto-investissement{
			padding-left: 50px;
			
				&::before{
					content:"";
					background: url(../img/picto_investissement.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
			&.picto-apport{
			padding-left: 50px;
			
				&::before{
					content:"";
					background: url(../img/picto_apport.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
			&.picto-surface{
			padding-left: 50px;
			
				&::before{
					content:"";
					background: url(../img/picto_surface.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
			&.picto-profil{
				padding-left: 50px;
				
				vertical-align: middle;
				&::before{
					content:"";
					background: url(../img/picto_profil.png);
					width: 50px;
					height: 50px;
					vertical-align: middle;
					position: absolute;
					left: 0;
					margin-top: -25px;
				}
			}
          }
          h3{
            font-size: 18px;
          }
        }
        .container-paragraph{
          .container();
          padding-top: 40px;
          padding-bottom: 40px;
          .field-type-text-long{
            text-align: justify;
          }
        }
        .paragraphs-item-parralax{
          margin-left: -@grid-gutter-width/2;
          margin-right: -@grid-gutter-width/2;
          height: 450px;
        }
        .bgred{

          &>.row{
              background: @red;
          }

          color: @white;
          .field-name-field-titre-principa .field-item,
          .field-name-field-titre-gauche .field-item,
          .field-name-field-titre-droit .field-item{
            color: @white;
          }
          .field-type-text-long{
            h3,h4{
              text-transform: uppercase;
            }
          }
        }
      }
      .paragraphs-item-content-2-cols{
        @media (max-width: @screen-xs-max) {
          .col-sm-6+.col-sm-6{
            margin-top: 40px;
          }
        }
        .field-name-field-titre-gauche .field-item,
        .field-name-field-titre-droit .field-item{
          color: @red;
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 10px;
          .glyphicon-check;
          &::before{
            .glyphicon;
            margin-right: 10px;
            font-size: initial;
          }
        }
        .field-name-field-titre-principa .field-item{
          color: @red;
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 10px;
          .glyphicon-check;
          &::before{
            .glyphicon;
            margin-right: 10px;
            font-size: initial;
          }
        }
        .field-name-field-introduction{
          margin-bottom: 10px;
        }
        .field-name-field-texte-gauche,
        .field-name-field-texte-droit{

        }
      }
      .paragraphs-item-content{
        .field-name-field-titre-principa .field-item{
          color: @red;
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 10px;
          .glyphicon-check;
          &::before{
            .glyphicon;
            margin-right: 10px;
            font-size: initial;
          }
        }
      }
      .paragraphs-item-3-photos{
        @media (max-width: @screen-xs-max) {
          img{
            margin-left: auto;
            margin-right: auto;
          }
          .col-sm-4+.col-sm-4{
            margin-top: 10px;
          }
        }
        @media (min-width: @screen-sm-min) {
          img{
            width: 100%;
            height: auto;
          }
        }

      }
      .paragraphs-item-4-photos,
      .paragraphs-item-5-photos{
        .field-item{
          position: relative;
          &::after{
            content:"";
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            border: 1px solid @white;
          }
        }
        @media (max-width: @screen-xs-max) {
          img{
            width: 100%;
            height: auto;
          }
          .col-sm-6,
          .col-sm-12{
            margin-top: 10px;
          }
        }
        @media (min-width: @screen-sm-min) {
          img{
            width: 100%;
            height: auto;
          }
          .field-name-field-photo-4 img{
            margin-top: @grid-gutter-width;
          }
        }

      }
      .paragraphs-item-photo-texte{
        .field-name-field-image{
          @media (max-width: @screen-xs-max) {
            margin-bottom: 20px;
          }
          @media (min-width: @screen-sm-min) {
            display: inline-block;
            width: 40%;
            vertical-align: middle;
          }
          img{
            margin-left: auto;
            margin-right: auto;
          }
        }
        .group-contenu{
          @media (min-width: @screen-sm-min) {
            display: inline-block;
            width: 60%;
            vertical-align: middle;
            padding-left: 15px;

          }
        }
      }
      .paragraphs-item-photo{
        img{
          margin-left: auto;
          margin-right: auto;
        }
      }
	  .paragraphs-item-texte-3-colonnes{
	  @media (min-width: @screen-sm-min) {
		  .rowcol{
			height: 100%;
			display: table-row;
			.col-sm-4{
				display: table-cell;
				float: none;
			}
			.col-sm-4+.col-sm-4{
				border-left: 2px solid #ffffff;
			}
		  }
	  }
        @media (max-width: @screen-xs-max) {
          .col-sm-4+.col-sm-4{
            margin-top: 40px;
          }
        }
        
        .field-name-field-titre-principa .field-item{
          color: @red;
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 10px;
          .glyphicon-check;
          &::before{
            .glyphicon;
            margin-right: 10px;
            font-size: initial;
          }
        }
        
      }
    }
  }
}
