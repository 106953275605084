.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
  position: relative;
  border-radius: 0px;
  border: 2px solid @white;
  color: @white;
  padding: 5px 10px 5px 45px;
  text-decoration: none;
  font-size: 19px;
  &::before{
    content: "\e258";
    font-family: 'Glyphicons Halflings';
    display: inline-block;
    border-right: 2px solid @white;
    padding: 0px 5px;
    margin-right: 5px;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  &:hover{
    border-color: @red;
    color: @red;
    background: @white;
    &::before{
      border-right-color: @red;
    }
  }
}
.btn-info-inverse {
  .button-variant(@btn-info-bg;@btn-info-color ; @btn-info-border);
  position: relative;
  border-radius: 0px;
  border: 2px solid @red;
  color: @red;
  padding: 5px 10px 5px 45px;
  text-decoration: none;
  font-size: 19px;
  &::before{
    content: "\e258";
    font-family: 'Glyphicons Halflings';
    display: inline-block;
    border-right: 2px solid @red;
    padding: 0px 5px;
    margin-right: 5px;
    position: absolute;
    left: 5px;
    top: 5px;
  }
  &:hover{
    border-color: @red;
    color: @white;
    background: @red;
    &::before{
      border-right-color: @white;
    }
  }
}
.btn-plus {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
  position: relative;
  border-radius: 0px;
  border: 2px solid @white;
  color: @white;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  &::before{
    //font-size: 15px;
    //line-height: 10px;
    content: "\e081";
    font-family: 'Glyphicons Halflings';
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
    vertical-align: sub;
    //color: @red;
    //background: @white;
    //border: 2px @white solid;
    //padding-top: 1px;
    line-height: 1;
	font-size: 18px;
  }
  &:hover{
    border-color: @red;
    color: @red;
    background: @white;

  }
}
