#return-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: rgba(135, 135, 135, 0.3);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    i {
        color: #fff;
        margin: 0;
        position: relative;
        left: 16px;
        top: 13px;
        font-size: 19px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    &:hover {
        background: rgba(221, 30, 51, 0.8);
        i {
            color: #fff;
            top: 5px;
        }
    }
}

.prefooter{
  padding-top: 35px;
  padding-bottom: 35px;
  .form-rejoindre{
    .block-title{
      color: @red;
      font-size: 23px;
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: 0px;
      text-align: center;
    }
    .col{
      border: none;
      box-shadow: none;
      margin: 0px;
      .panel-body{
        padding: 0px;
        .form-item{
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .col2 > .panel-body > .form-item{
      width: 50%;
      display: inline-block;
    }
    input,textarea{
      background: @grey;
      border-radius: 0px;

    }
    textarea{
      height: 280px;
    }
    .form-group{
      margin-bottom: 15px;
    }
    .form-actions{
      margin-top: 20px;
      text-align: center;
      .btn{
        .btn-info();
        text-transform: uppercase;
      }
    }

  }
  .block-rejoindre-link,
  .block-acces-boutique{
    text-align: center;
    margin-top: @margin-top-home;
    .block-title{
      color: @red;
      font-size: 23px;
      text-transform: uppercase;
      margin-bottom: 20px;
      margin-top: 0px;
    }
    p{
      a{
        .btn();
        .btn-info();
        text-transform: uppercase;
      }
    }

  }
  .block-fb-likebox{
    text-align: center;
  }
  .bloc_avec_img_de_fond{
    @media (min-width: @screen-sm-min) {
      height: 440px;
    }
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .content{
      @media (max-width: @screen-xs-max) {
        text-align: center;
      }
      @media (min-width: @screen-sm-min) {
          width: 50%;
      }

      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      padding: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .title{
          font-size: 22px;
          margin-bottom: 20px;
      }
      .text{

      }
      .link{
        margin-top: 20px;
        text-align: right;
        a{
          .btn();
          .btn-info();
          border: none;
        }
      }

    }
  }
}
.footer{
  background: @grey;
  margin-top: @margin-top-home;

  .fa{
    background: @red;
    border-radius: 50%;
    color: @white;
    font-size: 0px;
    width: 48px;
    height: 48px;
    padding: 10px;
    text-align: center;
    &::before{
      font-size: 30px;
    }
    &:hover{
      color: @red;
      background: @white;
    }
  }
  #block-menu-menu-reseaux-sociaux{
    text-align: center;
    .menu{
      li{
        display: inline-block;
        &+li{
          margin-left: 15px;
        }
      }
    }
  }
  #block-menu-menu-footer{
    margin-top: 10px;
    text-align: center;
    .menu{
      li{
        display: inline-block;
        &+li{
          &:before{
            content:"-";
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
        a{
          color: @black;
          display: inline-block;
          padding: 0px;
          &::first-letter{
            text-transform: uppercase;
          }
          &:hover{
            color: @red;
          }
        }
      }
    }
  }
  .block-copyright{
    text-align: center;
    margin-top: 20px;
  }
}
#nav-fixed-left{
  // display: none;
  position: fixed;
  top: 20%;
  right: 0;
  margin-right: 1rem;
  z-index: 1001;
  a{
    background: @red;
    border-radius: 50%;
    border: 1px solid @red;
    color: @white;
    width: 48px;
    height: 48px;
    padding: 0;
    text-align: center;
    &:hover{
      color: @red;
      background: @white;
    }
  }
  .fa {
    font-size: 0px;
    &:before {
      font-size: 30px;
      line-height: 48px;
    }
  }
  .li-commander{
    visibility: hidden;
  }
  .link-commander{
    position: relative;
    overflow: hidden;
    .start{
      display: inline-block;
      height: 46px;
      width: 100%;
      border-radius: 50%;
      transform: rotate(-72deg);
      font-size: 8px;
      margin-top: -16px;
      margin-left: 5px;
      span{
        position: absolute;
        width: 10px;
        left: 0;
        top: 0;
        transform-origin: bottom center;
        height: 23px;
        &.char1{
          transform: rotate(0deg);
        }
        &.char2{
          transform: rotate(18deg);
        }
        &.char3{
          transform: rotate(36deg);
        }
        &.char4{
          transform: rotate(54deg);
        }
        &.char5{
          transform: rotate(72deg);
        }
        &.char6{
          transform: rotate(90deg);
        }
        &.char7{
          transform: rotate(108deg);
        }
        &.char8{
          transform: rotate(126deg);
        }
        &.char9{
          transform: rotate(144deg);
        }
      }
    }
    .middle{
      display: none;
    }
    .end{
      font-size: 8px;
      display: inline-block;
      height: 46px;
      width: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 16px;
      margin-left: 8px;
      transform: translate(48deg);
      span{
        position: absolute;
        width: 10px;
        left: 0;
        bottom: 0;
        transform-origin: top center;
        height: 23px;
        &.char1{
          transform: rotate(0deg);
        }
        &.char2{
          transform: rotate(-22deg);
        }
        &.char3{
          transform: rotate(-44deg);
        }
        &.char4{
          transform: rotate(-66deg);
        }
        &.char5{
          transform: rotate(-88deg);
        }
      }
    }
  }
  li{
    + li{
      margin-top: 1rem;
    }
  }
}