.node-type-article{
  .field-name-lien-page-actualit-s{
    margin-top: 20px;
  }
  .field-name-field-date{
    font-weight: bold;
    font-style: italic;
    margin-bottom: 10px;
  }
}
