.page-node.node-type-restaurant{
  .page-header{
    display: none;
  }
  .gmap-control{
    height: auto !important;
  }
  .node-restaurant{
    margin-top: 20px;
	.tel{
		abbr{
			text-decoration: none;
			border: none;
		}
	}
    .field-name-title{
      h2{
        color: @red;
        font-weight: bold;
        font-size: 24px;
      }
    }
    .field-name-field-type-de-restauration{
      margin-top: 20px;
      .field-item{
        display: inline-block;
        color: @red;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        &+.field-item{
          &::before{
            content: "|";
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
    .group-horaire{
      &>h3{
        text-transform: uppercase;
        font-size: 19px;
      }
      .field-label{
        min-width: 100px;
      }
    }
    .field-name-service-de-livraison-et-r-servat{
      .block-title{
        text-transform: uppercase;
        font-size: 19px;
      }
      .view-content{
        .views-row{
          display: inline-block;
          width: 130px;
        }
      }
    }
    .row-center{
      padding-bottom: 20px;
    }
    .row-bottom{
      background: @red;
      color: @white;
      padding-top: 20px;
      padding-bottom: 20px;
    }
	.field-name-lien-page-restaurants{
		margin-top: 20px;
		padding-left: 15px;
	  }
    #block-views-carte-du-restaurant-block{

      .block-title{
        text-align: center;
      }
      .view-content{
        .view-grouping{
          .make-md-column(6);
		  .view-grouping-header{
			position: relative;
			margin-top: 10px;
			margin-bottom: 10px;
			&::before{
				width: 100%;
				height: 1px;
				position: absolute;
				background: #ffffff;
				top: 50%;
				left: 0;
				content: "";
				display: inline-block;
			}
			h3{
				margin: 0px;
				background: @red;
				display: inline-block;
				padding-right: 10px;
				position: relative;
				font-weight: bold;
			}
		  }
		  .views-field-field-nom{
			font-weight: bold;
			.prix{
				float: right;
				font-weight: normal;
				
			}
		  }
        }
      }
	  
    }
  }
}
