.page-node-7{
  .field-name-body{
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
  }
  .webform-client-form-7{
    .col2{
      border: none;
      box-shadow: none;
      margin: 0px;
      .panel-body{
        padding: 0px;
      }
    }
    .form-item{
      padding-left: 10px;
      padding-right: 10px;
    }
    .col2 > .panel-body > .form-item{
      width: 50%;
      display: inline-block;
    }
    input,textarea{
      background: @grey;
      border-radius: 0px;

    }
    textarea{
      height: 309px;
    }
    .form-group{
      margin-bottom: 15px;
    }
    .form-actions{
      margin-top: 20px;
      text-align: center;
      .btn{
        .btn-info();
        text-transform: uppercase;
        margin-top: 14px;
      }
    }

  }
  .block-coordonnees{
    @media (max-width: @screen-xs-max) {
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 40px;
    }
    margin-left: 40px;
    margin-right: 40px;
    background: @red;
    padding: 20px 20px 20px 80px;
    color: @white;
    font-size: 16px;
    a{
        color: @white;
    }
    .item{

      h3{
        position: relative;
        font-weight: bold;
        &::before{
          content: '';
          display: block;
          width: 36px;
          height: 36px;
          position: absolute;
          top: 0px;
          left: -60px;
          background:url(../img/picto-adresse.png) no-repeat;

        }
      }
      &:nth-child(3){
        h3::before{
          background:url(../img/picto-mail.png) no-repeat;
        }
      }
      &:nth-child(2){
        h3::before{
          background:url(../img/picto-tel.png) no-repeat;
        }
      }
      &:last-child{
        h3::before{
          background:url(../img/picto-horaire.png) no-repeat;
        }
      }
    }

  }
  .block-contact-restaurants{
    text-align: center;
    h3{
      color: @red;
      text-transform: uppercase;
    }
    p a{
      .btn();
      .btn-info();
	  text-transform:uppercase;
      margin-top: 10px;
    }
  }
}
